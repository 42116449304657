<template>
    <Layout class="container" :panels="panels" style="background-color: #01132C;" :padding="20" :gutter="20" :loading="loading" :pause="!show">
      
    </Layout>
</template>

<script>
import Layout from '@/components/Layout'
import moment from 'moment'
import UTIL from '@/utils'
moment.locale('zh-cn')
import {mapGetters} from 'vuex'
export default {
  name: "PanelBill",
  components:{Layout},
  props:['show'],
  data(){
    return {
      config:{}
    }
  },
  computed:{
    ...mapGetters('board',['recent_bid_projects','year_completed_projects','to_complete_projects','loading','history_untransfer_projects']),
    panels(){
      return [{
        x:0.6,y:0.3,w:8,h:1,
        control:"PanelCaption",
        data:{
          name:"收费情况",
          sub:"宁波高专成本合约系统数字化平台 v2.0.0"
        }
      },{
        x:0.5,y:1,w:7,h:9.6,
        control:"PanelBlBillplan"
      },
       {
        x:7.5,y:1,w:6,h:8,
        control:"PanelBlProduction",
      },
      {
        x:7.5,y:6.1,w:6,h:3,
        control:"PanelBlContract",
      },
      {
        x:13.5,y:6.3,w:5,h:5,control:"PanelDvProjects",data:{name:`历史未到账排行`,raw:this.history_untransfer_projects}
      },
      {
        x:13.5,y:1.3,w:5,h:5,control:"PanelDvProjects",data:{name:`新中标项目(半年内)`,raw:this.recent_bid_projects}
      },
      {
        x:18.5,y:1.3,w:5.5,h:5,control:"PanelDvProjects",data:{name:`2023年竣工项目`,raw:this.year_completed_projects}
      },
      {
        x:18.5,y:6.3,w:5.5,h:5,control:"PanelDvProjects",data:{name:`2023年即将竣工项目`,raw:this.to_complete_projects}
      },{
        x:14.5,y:0.3,w:9.5,h:1.5,control:"PanelTime"
      },]
    }
  },
}
</script>